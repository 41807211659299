<template>
  <section id="cod-rincian-penarikan-saldo">
    <div v-if="!loadDataAwal">
      <BModal
        id="failUploadPopup"
        ref="failUploadPopup"
        centered
        hide-header
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            class="mx-auto pt-2"
          >
          <h4 class="text-bold mt-1">
            Maaf, maksimal upload hanya 3 file.
          </h4>
          <button
            class="btn btn-primary btn-lg mt-2 mb-2"
            @click="$bvModal.hide('failUploadPopup')"
          >
            Oke
          </button>
        </div>
      </BModal>
      <BCard no-body>
        <BCardHeader>
          <h4 class="card-title text-24-bold">
            Persetujuan Penarikan
          </h4>
        </BCardHeader>
        <BCardBody>
          <div
            ref="fileform"
            class="mb-2 container-upload"
          >
            <h3
              class="text-24-bold text-center"
              style="font-size: 32px;color:#222222;"
            >
              Upload Bukti Transfer
            </h3>
            <BCard no-body>
              <form ref="fileform">
                <BCardBody style="padding:0 inherit;">
                  <div
                    ref="dropuploadarea"
                    class="wrapper-upload-collaborator mb-1"
                  >
                    <input
                      id="filebuktitransfer"
                      ref="filebuktitransfer"
                      type="file"
                      accept="image/*"
                      name="filebuktitransfer"
                      style="display: none;"
                      @change="previewFiles"
                    >
                    <b-img
                      :src="require('@/assets/images/elements/folder-open.png')"
                      height="76"
                      width="76"
                      class="mb-1"
                      alt="Folder Image"
                    />
                    <span
                      class="text-16-normal mb-2"
                      style="color: #828282;"
                    >
                      PNG, JPG dan JPEG file yang diperbolehkan
                    </span>
                    <span
                      class="text-24-bold mb-4"
                      style="color: #222222;font-size: 20px;"
                    >
                      Drag dan drop atau telusuri untuk memilih file
                    </span>
                    <b-button
                      variant="primary"
                      class="btn-custom-upload"
                      @click="uploadFile()"
                    >
                      Browse
                    </b-button>
                  </div>
                  <span
                    class="text-24-normal"
                    style="color: #222222;"
                  >
                    {{ fileUploadCount }} dari {{ filesUploaded.length }} diupload
                  </span>
                  <div class="mt-1">
                    <BCard no-body>
                      <BCardBody style="padding:0;">
                        <div
                          v-for="(data,idx) in filesSettled"
                          :key="idx"
                          style="display: grid;grid-row-gap: 1rem;"
                        >
                          <div class="d-flex align-items-center">
                            <div
                              class="icon-uploaded d-flex justify-content-center align-items-center"
                            >
                              <feather-icon
                                icon="FileIcon"
                                size="2x"
                              />
                            </div>
                            <div class="progressUpload">
                              <div class="d-flex justify-content-between">
                                <span class="text-bukti-transfer">
                                  {{ data.name }}
                                </span>
                                <span
                                  class="text-bukti-transfer text-[#E31A1A] cursor-pointer"
                                  @click="handleBatalDataUpload(data)"
                                >
                                  Batal
                                </span>
                              </div>
                              <b-progress
                                :value="valueProgressUpload"
                                :max="maxProgressUpload"
                                class="my-1"
                              />
                              <div class="d-flex justify-content-between">
                                <span class="text-filesize-bukti-transfer">
                                  {{ calculateSizeFile(data.size) }}
                                </span>
                                <span class="text-filesize-bukti-transfer">
                                  {{ valueProgressUpload }}%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </BCardBody>
                    </BCard>
                  </div>
                  <div style="display: flex;justify-content:center;">
                    <BButton
                      v-if="filesSettled.length > 0"
                      variant="primary"
                      class="btn-custom-upload"
                      :disabled="disableBtn"
                      @click="handleKonfirmasi()"
                    >
                      <BSpinner
                        v-if="isLoading"
                        small
                        variant="light"
                      />
                      Konfirmasi
                    </BButton>
                  </div>
                </BCardBody>
              </form>
            </BCard>
          </div>
        </BCardBody>
      </BCard>
    </div>

    <div
      v-else
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
  </section>
</template>

<script>
import { collaboratorAxiosIns } from '@/libs/axios'

export default {
  data() {
    return {
      loadDataAwal: true,
      filesUploaded: [],
      filesSettled: [],
      fileUploadCount: 0,
      valueProgressUpload: 0,
      maxProgressUpload: 100,
      dragAndDropCapable: false,
      isLoading: false,
      disableBtn: false,
      dataUser: this.$route.params,
    }
  },
  mounted() {
    this.loadDataAwal = false
    this.dragAndDropCapable = this.determineDragAndDropCapable()

    this.$nextTick(() => {
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(evt => {
          this.$refs.fileform.addEventListener(evt, e => {
            e.preventDefault()
            e.stopPropagation()
          }, false)
        })

        this.$refs.fileform.addEventListener('drop', e => {
          const { files } = e.dataTransfer
          for (let index = 0; index < files.length; index += 1) {
            if (files.length > 3) {
              this.$refs.failUploadPopup.show()
            }
          }
          this.handleFiles(files)
        })
      }
    })
  },
  methods: {
    determineDragAndDropCapable() {
      const div = document.createElement('div')
      return (('draggable' in div)
              || ('ondragstart' in div && 'ondrop' in div))
              && 'FormData' in window
              && 'FileReader' in window
    },
    uploadFile() {
      const fileInputElement = this.$refs.filebuktitransfer
      fileInputElement.click()
    },
    previewFiles(event) {
      const data = event.target.files[0]
      this.handleFiles(data)
    },
    preventDefaults(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    handleFiles(files) {
      this.filesSettled = []
      this.valueProgressUpload = 0
      this.filesSettled = [files]
      const intervalId = setInterval(() => {
        if (this.valueProgressUpload < 100) {
          this.disableBtn = true
          this.valueProgressUpload += 10
        } else {
          clearInterval(intervalId)
          this.disableBtn = false
          this.fileUploadCount = 1
          this.filesUploaded = []
          this.filesUploaded.push(files)
        }
      }, 100)
    },
    handleBatalDataUpload(file) {
      const fileListArr = Array.from(this.filesSettled)
      const filteredFiles = fileListArr.filter(x => (x.name !== file.name && x.lastModified !== file.lastModified))
      this.filesSettled = filteredFiles
      this.fileUploadCount = filteredFiles.length
      this.filesUploaded = filteredFiles
    },
    async handleKonfirmasi() {
      this.isLoading = true
      this.disableBtn = true
      const url = `/v1/admin/withdrawals/${this.dataUser.id}/confirm`
      const formData = new FormData()
      formData.append('user_id', this.dataUser.user_id)
      formData.append('status', 'approved')
      formData.append('attachment', this.filesUploaded[0])
      formData.append('note', this.dataUser.note)

      await collaboratorAxiosIns.put(url, formData)
        .then(() => {
          this.$toast_success({ message: 'Withdrawal Berhasil', timeout: 1000 })
          this.isLoading = false
          this.disableBtn = true
          this.$router.go(-1)
        })
        .catch(err => {
          this.$toast_error({ message: 'Withdrawal Gagal', timeout: 1000 })
          this.isLoading = false
          this.disableBtn = true
        })
    },
    calculateSizeFile(size) {
      const sizesUnit = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (size === 0) return '0 Byte'
      const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)), 10)
      return `${Math.round(size / (1024 ** i), 2)} ${sizesUnit[i]}`
    },
  },
}
</script>

<style scoped lang="scss">
@import '../affiliate/Withdrawal.scss';

.wrapper-upload-collaborator {
  width: 70vw;
    height: 364px;
    display: grid;
    justify-items: center;
    align-content: center;
    grid-row-gap: 0;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #FFECE9;
    border: 2px dashed #F95031
}
</style>
